@import url(https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Libre+Barcode+39+Text&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Wire+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Wire+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@1,300&display=swap);
body{
  font-family: 'Wire One', sans-serif;
}



* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-color: #161616;
    overflow: hidden;
    font-family: 'Zen Kurenaido', sans-serif;

}

.header {
    position: absolute;
    display: flex;
    height: 150px;
    width: 100%;
    z-index: 10000;
    justify-content: space-between;
    align-items: center;
    color: white;
    margin-top: 7vh;
}

.header .logo {
    margin-left: 30px;
    font-size: 60px;
    /* font-weight: bold; */
    font-family: 'Libre Barcode 39 Text', cursive;

}

.header ul {
    list-style: none;
    text-align: right;
    margin-right: 30px;
    /* margin-top: 20px; */
}

.header ul li .link {
    font-size: 40px;
    font-family: 'Darker Grotesque', sans-serif;
    color: white;
    /* font-weight: bold; */
    margin-top: 5px;
    text-decoration: none;
    transition: 0.5s ease-in;

}

.header ul li .link:hover {
    color: black;
    padding-left: 70px;
    background-color: white;
}


@media screen and (max-width: 767px) {
    .header .logo {
        margin-left: 30px;
        font-size: 30px;
        font-family: 'Libre Barcode 39 Text', cursive;

    }

    .header ul {
        list-style: none;
        text-align: right;
        margin-right: 30px;
    }

    .header ul li .link {
        font-size: 30px;
        font-family: 'Darker Grotesque', sans-serif;
        color: white;
        /* font-weight: bold; */
        margin-top: 5px;
        text-decoration: none;
        transition: 0.6s ease-in;
        position: relative;
        top: 45px;

    }

    .header ul li .link:hover {
        color: black;
        padding-left: 70px;
        background-color: white;
    }
}

@media screen and (max-height: 570px) {
    body {
        background-color: #161616;
        font-family: 'Zen Kurenaido', sans-serif;
        overflow-x: hidden;
        width: 100%;
        box-sizing: border-box;

    }

    .header .logo {
        margin-left: 30px;
        font-size: 30px;
        font-family: 'Libre Barcode 39 Text', cursive;

    }

    .header ul {
        list-style: none;
        text-align: right;
        margin-right: 30px;
    }

    .header ul li .link {
        font-size: 20px;
        font-family: 'Darker Grotesque', sans-serif;
        color: white;
        /* font-weight: bold; */
        margin-top: 5px;
        text-decoration: none;
        transition: 0.6s ease-in;
        position: relative;
        top: 25px;

    }

    .header ul li .link:hover {
        color: black;
        padding-left: 70px;
        background-color: white;
    }


}

@media screen and (max-width: 768px) {
    .header {
        position: absolute;
        display: flex;
        height: 120px;
        width: 100%;
        z-index: 10000;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin: 0;
    }
}
.container-home {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    position: relative;
    top: 40px;
}

.container-home h1 {
    font-family: 'Darker Grotesque', sans-serif;
    color: rgb(161, 0, 0);
    font-size: 8vw;
    text-transform: uppercase;

}

.container-home h2 {
    font-family: 'Darker Grotesque', sans-serif;
    color: white;
    font-size: 5vw;
    text-transform: uppercase;
    position: relative;
    bottom: 80px;
    font-weight: lighter;
    font-style: italic;
}

.container-home h3 {
    font-family: 'Darker Grotesque', sans-serif;
    color: white;
    font-size: 3vw;
    text-transform: uppercase;
    position: relative;
    bottom: -70px;
    font-weight: lighter;
    font-style: italic;
}

.home-image {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: yellow;
}

.home-overlay {
    background: url(/static/media/paul3.cb2285ef.jpg) no-repeat;
    background-size: cover;
    opacity: 0.4;
}


@media screen and (max-width: 768px) {
    .container-home {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 10px;
    }

    .container-home h1 {
        font-family: 'Darker Grotesque', sans-serif;
        color: rgb(161, 0, 0);
        font-size: 80px;
        line-height: 0.9;
        text-transform: uppercase;
        position: relative;
        left: 40px;
        word-spacing: 1px;

    }

    .container-home h2 {
        font-family: 'Darker Grotesque', sans-serif;
        color: white;
        font-size: 60px;
        text-transform: uppercase;
        position: relative;
        bottom: 100px;
        left: 60px;
        font-weight: lighter;
        font-style: italic;

    }

    .container-home h3 {
        font-family: 'Darker Grotesque', sans-serif;
        color: white;
        font-size: 30px;
        text-transform: uppercase;
        position: relative;
        bottom: -120px;
        left: -50px;
        font-weight: lighter;
        font-style: italic;

    }

    .home-image {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-color: yellow;
    }

    .home-overlay {
        background: url(/static/media/paul3.cb2285ef.jpg) 50% 50% no-repeat;
        background-size: cover;
        opacity: 0.4;
    }
}

@media screen and (max-height: 570px) {
    .container-home {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 10px;
    }

    .container-home h1 {
        font-family: 'Darker Grotesque', sans-serif;
        color: rgb(161, 0, 0);
        font-size: 50px;
        text-transform: uppercase;
        position: relative;
        left: 40px;
        word-spacing: 1px;

    }

    .container-home h2 {
        font-family: 'Darker Grotesque', sans-serif;
        color: white;
        font-size: 40px;
        text-transform: uppercase;
        position: relative;
        bottom: 60px;
        left: 60px;
        font-weight: lighter;
        font-style: italic;

    }

    .container-home h3 {
        font-family: 'Darker Grotesque', sans-serif;
        color: white;
        font-size: 30px;
        text-transform: uppercase;
        position: relative;
        bottom: -80px;
        left: -50px;
        font-weight: lighter;
        font-style: italic;

    }

    .home-image {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-color: yellow;
    }

    .home-overlay {
        background: url(/static/media/paul3.cb2285ef.jpg) 50% 50% no-repeat;
        background-size: cover;
        opacity: 0.4;
    }
}
.transition-effect{
    position: absolute;
    z-index: 1000000000000000;
    background-color: rgba(161, 0, 0, 0.623);
    top: 0;
    width: 100%;
    height: 100vh;
}


/* rgb(126, 126, 126); */
.container-contact {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
}

.container-contact h1 {
    font-family: 'Darker Grotesque', sans-serif;
    color: white;
    font-size: 5vw;
    text-transform: uppercase;

}

.container-contact h2 {
    font-family: 'Darker Grotesque', sans-serif;
    color: rgb(161, 0, 0);
    font-size: 3vw;
    text-transform: uppercase;
    position: relative;
    bottom: -100px;
    left: -90px;
    font-weight: lighter;
    font-style: italic;

}

.contact-image {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: yellow;
}

.contact-overlay {
    background: url(/static/media/paul4.1f0335b4.jpg) no-repeat;
    background-size: cover;
    opacity: 0.5;
}


@media screen and (max-width: 768px) {
    .container-contact {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 10px;
    }

    .container-contact h1 {
        font-family: 'Darker Grotesque', sans-serif;
        color: rgb(161, 0, 0);
        font-size: 50px;
        text-transform: uppercase;
        position: relative;
        left: 40px;
        word-spacing: 1px;

    }

    .container-contact h2 {
        font-family: 'Darker Grotesque', sans-serif;
        color: white;
        font-size: 20px;
        text-transform: uppercase;
        position: relative;
        bottom: -140px;
        left: -90px;
        font-weight: lighter;
        font-style: italic;

    }

    .contact-image {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-color: yellow;
    }

    .contact-overlay {
        background: url(/static/media/paul4.1f0335b4.jpg) 50% 50% no-repeat;
        background-size: cover;
        opacity: 0.5;
    }
}
.container-about {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    position: relative;
    top: 60px;
}

.container-about p {
    font-family: 'Darker Grotesque', sans-serif;
    color: white;
    font-size: 20px;
    text-transform: uppercase;
    margin-left: 10px;


}

span {
    color: rgb(161, 0, 0);
    font-size: 40px;
    margin: 0 10px;
    font-weight: bold;
}

.container-about .paragraph-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 30px;
}

/* .paragraph3 {
    margin-left: 10px;
} */


.about-image {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: yellow;
}

.about-overlay {
    background: url(/static/media/eugene1.9dfab4fd.jpg) no-repeat;
    background-size: cover;
    opacity: 0.6;
}


@media screen and (max-width: 768px) {
    .container-about {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
        position: relative;
        top: 0;
    }

    .container-about .paragraph1 {
        font-family: 'Darker Grotesque', sans-serif;
        color: white;
        font-size: 2.3vh;
        text-transform: uppercase;
        position: relative;
        top: 70px;
        margin: 30px 40px;


    }

    .container-about p {
        font-size: 2.3vh;
    }

    span {
        color: rgb(161, 0, 0);
        margin: 0 10px;
        font-size: 30px;
        font-weight: bold;
    }

    .container-about .paragraph-flex {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        position: relative;
        top: 60px;
        font-size: 15px;
        /* margin-left: 20px; */
        margin-right: 20px;
    }

    .about-image {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-color: yellow;
    }

    .about-overlay {
        background: url(/static/media/eugene1.9dfab4fd.jpg) 50% 50% no-repeat;
        background-size: cover;
        opacity: 0.5;
    }
}

/* @media screen and (max-height: 570px) {
    .container-about {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 10px;
    }

    .container-about .paragraph1 {
        font-family: 'Darker Grotesque', sans-serif;
        color: white;
        font-size: 15px;
        text-transform: uppercase;
        position: relative;
        top: 70px;
        margin: 30px 40px;



    }

    span {
        color: rgb(161, 0, 0);
        margin: 0 10px;
        font-size: 20px;
        font-weight: bold;
    }

    .container-about .paragraph-flex {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        position: relative;
        top: 40px;
        font-size: 10px;
        margin-left: 20px;
        margin-right: 20px;
    }


    .about-image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 700px;
        height: 950px;
        overflow: hidden;
        background-color: yellow;
    }

    .about-overlay {
        background: url(./Assets/eugene1.jpg) 50% 50% no-repeat;
        background-size: cover;
        opacity: 0.5;
    }


} */
/* .Projects {
    display: flex;
    justify-content: center;
    align-items: center;
} */

.container-project {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 45vh 0;
    /* width: 50%; */
    height: 30%;
    /* position: absolute; */
    /* top: 0; */
    /* left: 50%;
    bottom: 50%;
    right: 50%; */


}

.container-project a {
    margin: 10px;
    transition: 300ms;
    text-decoration: none;
    /* height: 30vh; */
    color: white;
    font-family: 'Cormorant Garamond', serif;
    letter-spacing: 1px;
    font-size: 3vh;

}

.container-project div {
    height: 30vh;
    width: 30vh;
    background-image: url(/static/media/projectimg.4fef5c17.jpg);
    background-size: cover;
    padding: 4vh;
    display: flex;
    align-items: flex-end;
    transition: 300ms;

}

.container-project div p {
    transition: 100ms;
}

.container-project div:hover {
    color: rgb(161, 0, 0)
}

.container-project div:hover p {
    -webkit-transform: scale(1.2);
            transform: scale(1.2)
}



.container-project img {
    height: 30vh;

}

.container-project a:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1)
}

.container-project h2 {
    font-family: 'Darker Grotesque', sans-serif;
    color: rgb(161, 0, 0);
    font-size: 3vw;
    text-transform: uppercase;
    position: relative;
    bottom: -100px;
    left: -90px;
    font-weight: lighter;
    font-style: italic;

}

.project-image {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: yellow;
}

.project-overlay {
    background: url(/static/media/lines2.ab9fcf0c.jpg) no-repeat;
    background-size: cover;
    opacity: 0.5;
}


@media screen and (max-width: 768px) {
    .container-project {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding: 30vh 5vh;
        /* width: 50%; */
        height: 30%;
        /* position: absolute; */
        /* top: 0; */
        /* left: 50%;
        bottom: 50%;
        right: 50%; */

    }

    .container-project img {
        height: 15vh;

    }

    .container-project div {
        height: 15vh;
        width: 15vh;
        background-image: url(/static/media/projectimg.4fef5c17.jpg);
        background-size: cover;
        padding: 2vh;
    }

    .container-project a {
        margin: 10px;
        transition: 300ms;
        text-decoration: none;
        color: white;
        letter-spacing: 1px;
        font-size: 2vh;
        text-align: start;

    }



    .container-project img {
        height: 17vh;

    }


    .project-overlay {
        background: url(/static/media/lines2.ab9fcf0c.jpg) 50% 50% no-repeat;
        background-size: cover;
        opacity: 0.5;
    }
}
