@import url('https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@1,300&display=swap');


/* .Projects {
    display: flex;
    justify-content: center;
    align-items: center;
} */

.container-project {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 45vh 0;
    /* width: 50%; */
    height: 30%;
    /* position: absolute; */
    /* top: 0; */
    /* left: 50%;
    bottom: 50%;
    right: 50%; */


}

.container-project a {
    margin: 10px;
    transition: 300ms;
    text-decoration: none;
    /* height: 30vh; */
    color: white;
    font-family: 'Cormorant Garamond', serif;
    letter-spacing: 1px;
    font-size: 3vh;

}

.container-project div {
    height: 30vh;
    width: 30vh;
    background-image: url(./Assets/projectimg.jpg);
    background-size: cover;
    padding: 4vh;
    display: flex;
    align-items: flex-end;
    transition: 300ms;

}

.container-project div p {
    transition: 100ms;
}

.container-project div:hover {
    color: rgb(161, 0, 0)
}

.container-project div:hover p {
    transform: scale(1.2)
}



.container-project img {
    height: 30vh;

}

.container-project a:hover {
    transform: scale(1.1)
}

.container-project h2 {
    font-family: 'Darker Grotesque', sans-serif;
    color: rgb(161, 0, 0);
    font-size: 3vw;
    text-transform: uppercase;
    position: relative;
    bottom: -100px;
    left: -90px;
    font-weight: lighter;
    font-style: italic;

}

.project-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: yellow;
}

.project-overlay {
    background: url(./Assets/lines2.jpg) no-repeat;
    background-size: cover;
    opacity: 0.5;
}


@media screen and (max-width: 768px) {
    .container-project {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding: 30vh 5vh;
        /* width: 50%; */
        height: 30%;
        /* position: absolute; */
        /* top: 0; */
        /* left: 50%;
        bottom: 50%;
        right: 50%; */

    }

    .container-project img {
        height: 15vh;

    }

    .container-project div {
        height: 15vh;
        width: 15vh;
        background-image: url(./Assets/projectimg.jpg);
        background-size: cover;
        padding: 2vh;
    }

    .container-project a {
        margin: 10px;
        transition: 300ms;
        text-decoration: none;
        color: white;
        letter-spacing: 1px;
        font-size: 2vh;
        text-align: start;

    }



    .container-project img {
        height: 17vh;

    }


    .project-overlay {
        background: url(./Assets/lines2.jpg) 50% 50% no-repeat;
        background-size: cover;
        opacity: 0.5;
    }
}