.container-about {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    position: relative;
    top: 60px;
}

.container-about p {
    font-family: 'Darker Grotesque', sans-serif;
    color: white;
    font-size: 20px;
    text-transform: uppercase;
    margin-left: 10px;


}

span {
    color: rgb(161, 0, 0);
    font-size: 40px;
    margin: 0 10px;
    font-weight: bold;
}

.container-about .paragraph-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 30px;
}

/* .paragraph3 {
    margin-left: 10px;
} */


.about-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: yellow;
}

.about-overlay {
    background: url(./Assets/eugene1.jpg) no-repeat;
    background-size: cover;
    opacity: 0.6;
}


@media screen and (max-width: 768px) {
    .container-about {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
        position: relative;
        top: 0;
    }

    .container-about .paragraph1 {
        font-family: 'Darker Grotesque', sans-serif;
        color: white;
        font-size: 2.3vh;
        text-transform: uppercase;
        position: relative;
        top: 70px;
        margin: 30px 40px;


    }

    .container-about p {
        font-size: 2.3vh;
    }

    span {
        color: rgb(161, 0, 0);
        margin: 0 10px;
        font-size: 30px;
        font-weight: bold;
    }

    .container-about .paragraph-flex {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        position: relative;
        top: 60px;
        font-size: 15px;
        /* margin-left: 20px; */
        margin-right: 20px;
    }

    .about-image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-color: yellow;
    }

    .about-overlay {
        background: url(./Assets/eugene1.jpg) 50% 50% no-repeat;
        background-size: cover;
        opacity: 0.5;
    }
}

/* @media screen and (max-height: 570px) {
    .container-about {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 10px;
    }

    .container-about .paragraph1 {
        font-family: 'Darker Grotesque', sans-serif;
        color: white;
        font-size: 15px;
        text-transform: uppercase;
        position: relative;
        top: 70px;
        margin: 30px 40px;



    }

    span {
        color: rgb(161, 0, 0);
        margin: 0 10px;
        font-size: 20px;
        font-weight: bold;
    }

    .container-about .paragraph-flex {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        position: relative;
        top: 40px;
        font-size: 10px;
        margin-left: 20px;
        margin-right: 20px;
    }


    .about-image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 700px;
        height: 950px;
        overflow: hidden;
        background-color: yellow;
    }

    .about-overlay {
        background: url(./Assets/eugene1.jpg) 50% 50% no-repeat;
        background-size: cover;
        opacity: 0.5;
    }


} */