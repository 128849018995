@import url('https://fonts.googleapis.com/css2?family=Libre+Barcode+39+Text&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Wire+One&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-color: #161616;
    overflow: hidden;
    font-family: 'Zen Kurenaido', sans-serif;

}

.header {
    position: absolute;
    display: flex;
    height: 150px;
    width: 100%;
    z-index: 10000;
    justify-content: space-between;
    align-items: center;
    color: white;
    margin-top: 7vh;
}

.header .logo {
    margin-left: 30px;
    font-size: 60px;
    /* font-weight: bold; */
    font-family: 'Libre Barcode 39 Text', cursive;

}

.header ul {
    list-style: none;
    text-align: right;
    margin-right: 30px;
    /* margin-top: 20px; */
}

.header ul li .link {
    font-size: 40px;
    font-family: 'Darker Grotesque', sans-serif;
    color: white;
    /* font-weight: bold; */
    margin-top: 5px;
    text-decoration: none;
    transition: 0.5s ease-in;

}

.header ul li .link:hover {
    color: black;
    padding-left: 70px;
    background-color: white;
}


@media screen and (max-width: 767px) {
    .header .logo {
        margin-left: 30px;
        font-size: 30px;
        font-family: 'Libre Barcode 39 Text', cursive;

    }

    .header ul {
        list-style: none;
        text-align: right;
        margin-right: 30px;
    }

    .header ul li .link {
        font-size: 30px;
        font-family: 'Darker Grotesque', sans-serif;
        color: white;
        /* font-weight: bold; */
        margin-top: 5px;
        text-decoration: none;
        transition: 0.6s ease-in;
        position: relative;
        top: 45px;

    }

    .header ul li .link:hover {
        color: black;
        padding-left: 70px;
        background-color: white;
    }
}

@media screen and (max-height: 570px) {
    body {
        background-color: #161616;
        font-family: 'Zen Kurenaido', sans-serif;
        overflow-x: hidden;
        width: 100%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;

    }

    .header .logo {
        margin-left: 30px;
        font-size: 30px;
        font-family: 'Libre Barcode 39 Text', cursive;

    }

    .header ul {
        list-style: none;
        text-align: right;
        margin-right: 30px;
    }

    .header ul li .link {
        font-size: 20px;
        font-family: 'Darker Grotesque', sans-serif;
        color: white;
        /* font-weight: bold; */
        margin-top: 5px;
        text-decoration: none;
        transition: 0.6s ease-in;
        position: relative;
        top: 25px;

    }

    .header ul li .link:hover {
        color: black;
        padding-left: 70px;
        background-color: white;
    }


}

@media screen and (max-width: 768px) {
    .header {
        position: absolute;
        display: flex;
        height: 120px;
        width: 100%;
        z-index: 10000;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin: 0;
    }
}