@import url('https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@300&display=swap');

.container-contact {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
}

.container-contact h1 {
    font-family: 'Darker Grotesque', sans-serif;
    color: white;
    font-size: 5vw;
    text-transform: uppercase;

}

.container-contact h2 {
    font-family: 'Darker Grotesque', sans-serif;
    color: rgb(161, 0, 0);
    font-size: 3vw;
    text-transform: uppercase;
    position: relative;
    bottom: -100px;
    left: -90px;
    font-weight: lighter;
    font-style: italic;

}

.contact-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: yellow;
}

.contact-overlay {
    background: url(./Assets/paul4.jpg) no-repeat;
    background-size: cover;
    opacity: 0.5;
}


@media screen and (max-width: 768px) {
    .container-contact {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 10px;
    }

    .container-contact h1 {
        font-family: 'Darker Grotesque', sans-serif;
        color: rgb(161, 0, 0);
        font-size: 50px;
        text-transform: uppercase;
        position: relative;
        left: 40px;
        word-spacing: 1px;

    }

    .container-contact h2 {
        font-family: 'Darker Grotesque', sans-serif;
        color: white;
        font-size: 20px;
        text-transform: uppercase;
        position: relative;
        bottom: -140px;
        left: -90px;
        font-weight: lighter;
        font-style: italic;

    }

    .contact-image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-color: yellow;
    }

    .contact-overlay {
        background: url(./Assets/paul4.jpg) 50% 50% no-repeat;
        background-size: cover;
        opacity: 0.5;
    }
}